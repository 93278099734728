import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_5/sub_3/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      main_header: mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-5-3-0_header"}}) {
        body
      }
      main_content: mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-5-3-0_content"}}) {
        body
      }
      year_1920: mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-5-3-1"}}) {
        body
      }
      year_1939: mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-5-3-2"}}) {
        body
      }
      year_1945: mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-5-3-3"}}) {
        body
      }
      year_1989: mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-5-3-4"}}) {
        body
      }
    }
  `);

  return (
    <Template query={query} />
  );
};


export default Slide;
